@layer utilities {
  .break-word {
    word-break: break-word;
  }
  .flex-centered {
    @apply flex items-center justify-center;
  }
  .flex-between {
    @apply flex items-center justify-between;
  }
  .flex-inline-centered {
    @apply inline-flex items-center justify-center;
  }
  .device-rotation-notify {
    @apply fixed top-0 left-0 right-0 bottom-0 z-50 content-center hidden items-center justify-center h-screen bg-[#787878];
  }
}
