@layer components {
  /* privacy page */
  .privacy-title {
    @apply text-40 font-bold leading-58;
  }
  .privacy-sub-title {
    @apply px-2 py-1 text-2xl font-bold bg-secondary text-white;
  }
  .privacy-sub-title-2 {
    @apply text-lg font-bold;
  }
  .privacy-sub-title-3 {
    @apply text-lg font-medium inline-block after:w-full after:h-1 after:bg-secondary after:block;
  }
  .privacy-content-container {
    @apply p-4 rounded bg-grey-100;
  }
  .privacy-sub-content {
    @apply text-sm pl-4 text-note-200;
  }
  .privacy-link {
    @apply inline-block underline underline-offset-2 text-primary;
  }
  .line-icon {
    @apply block ease-linear duration-300 rounded-sm h-0.5 w-[1.625rem] bg-secondary;
  }
}
